<template>
  <body-with-header class="h-100" title="راه‌های ارتباطی با ما" :ads="true">
    <div class="m-1 m-md-3" v-once>
      <div class="main-card">
        <CRow class="p-4" align-horizontal="center">
          <CCol col="12" md="6" class="vertical-g-9">
            <CAlert color="info">
              میتوانید از طریق راه‌های زیر با بخش‌های مختلف مجموعه صحراشرق کیمیا
              در ارتباط باشید!
            </CAlert>
            <strong class="text-muted"> آدرس </strong>
            <p>
              مشهد، بزرگراه شهید شوشتری، ابتذای بزرگراه شهید کلانتری، اولین خروجی
              سمت راست، مرکز تحقیقات کشاورزی
            </p>
            <strong class="text-muted">مدیرعامل(مهندس نوری)</strong>
            <p>05133824177 - 09153254977</p>
            <strong class="text-muted"> واحد حسابداری</strong>
            <p>05133824288</p>
            <strong class="text-muted"> واحد فنی (پایش ماهواره ای)</strong>
            <p>05133824119</p>
            <strong class="text-muted"> واحد فنی (آبیاری)</strong>
            <p>05133825026</p>
            <strong class="text-muted"> مدیریت بازرگانی</strong>
            <p>05133823129- 09385224977</p>
          </CCol>
          <CCol col="12" md="6" class="p-0 justify-self-center">
            <div class="base-card" id="map"/>
          </CCol>
        </CRow>
        <CRow
          align-horizontal="center"
          class="my-3 text-center"
          style="color: cadetblue"
        >
        <span class="ml-3">
          <button
            class="socialBtn"
            @click="
              openUrl(
                ' https://instagram.com/sahrashargh.ir?utm_medium=copy_link'
              )
            "
          >
            <CIcon name="cib-instagram" size="xl"/>
          </button>
        </span>
          <span class="ml-3">
          <button
            class="socialBtn"
            @click="openUrl('instagram.com/sahrashargh.ir')"
          >
            <CIcon name="cib-youtube" size="xl"/>
          </button>
        </span>
          <span class="ml-3">
          <button class="socialBtn" @click="openUrl(' https://twitter.com')">
            <CIcon name="cib-twitter" size="xl"/>
          </button>
        </span>
          <span class="ml-3">
          <button class="socialBtn" @click="openUrl(' https://facebook.com')">
            <CIcon name="cib-facebook" size="xl"/>
          </button>
        </span>
        </CRow>
      </div>
    </div>
  </body-with-header>
</template>

<script>
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import BodyWithHeader from "../../components/bodyWithHeader.vue";

export default {
  components: {BodyWithHeader},
  data() {
    return {
      map: null,
      center: [59.629750588092726 , 36.21928523982986],
    };
  },
  methods: {
    openUrl(url) {
      window.open(url);
    },
  },
  mounted() {
    mapboxgl.accessToken =
      "pk.eyJ1IjoiaGFtaWRrYXplbXphZGVoIiwiYSI6ImNrcTJnNmY4NTAzczMybm85bDNvOGJ4NTYifQ.uEM3b50GGJ0aCbsXL3-O7A";

    if (mapboxgl.getRTLTextPluginStatus() === "unavailable") {
      mapboxgl.setRTLTextPlugin(
        "https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js",
        null,
        true // Lazy load the plugin
      );
    }
    this.map = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/hamidkazemzadeh/ckqgm1p580ctf17o2kalvw6wu",
      center: this.center, // starting position [lng, lat]
      zoom: 15, // starting zoom
      attributionControl: false,
    });

    new mapboxgl.Marker({
      draggable: false,
    })
      .setLngLat(this.center)
      .addTo(this.map);
    this.map.on("load", () => {
      // TODO: Here we want to load a layer
      // TODO: Here we want to load/setup the popup
    });
  },
};
</script>

<style scoped>

body {
  margin: 0;
  padding: 0;
}

#map {
  height: 100%;
  width: 100%;
}

.socialBtn {
  background: transparent;
  border: 0;
  padding: 0;
  color: var(--secondary);
}
</style>
